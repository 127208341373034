import { getError } from '../../utils/requestUtils';
import {
  ERROR, GET_DEPOSIT, GET_DEPOSITS, SAVE_DEPOSIT, START, SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  deposits: [],
  deposit: null,

  getDeposit: {},
  getDeposits: {},
  saveDeposit: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_DEPOSIT + START:
      return { ...state, getDeposit: { loading: true } };
    case GET_DEPOSIT + SUCCESS:
      return { ...state, deposit: data.item, getDeposit: { loading: false } };
    case GET_DEPOSIT + ERROR:
      return { ...state, getDeposit: { loading: false, error } };

    case GET_DEPOSITS + START:
      return { ...state, getDeposits: { loading: true } };
    case GET_DEPOSITS + SUCCESS:
      return { ...state, deposits: data.items, getDeposits: { loading: false } };
    case GET_DEPOSITS + ERROR:
      return { ...state, getDeposits: { loading: false, error } };

    case SAVE_DEPOSIT + START:
      return { ...state, saveDeposit: { loading: true } };
    case SAVE_DEPOSIT + SUCCESS:
      return { ...state, saveDeposit: { loading: false }, deposit: data.item };
    case SAVE_DEPOSIT + ERROR:
      return { ...state, saveDeposit: { loading: false, error } };

    default:
      return { ...state };
  }
}
